.react-tel-input {
    .form-control {
        width: 100%;
        height: 40px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
}

#lien-he {
    h6 {
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-size: 20px;
    }
}



@media (max-width: 767px) {
    #lien-he .container {
        padding-bottom: 80px !important;
    }
}